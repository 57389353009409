// ProgressBar.js
import React, { useState, useEffect } from "react";
import "./ProgressBar.css";
import LinearProgress from "@mui/material/LinearProgress";
const ProgressBar = ({ isVertical }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        // Increment progress by 1 step
        if (oldProgress === 0) {
          return 20; // Start from step 2 (20%)
        } else if (oldProgress === 20) {
          return 50; // Move to step 3 (50%)
        } else if (oldProgress === 50) {
          return 100; // Move to step 5 (100%)
        } else {
          return 0; // Reset to step 2 (0%)
        }
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // return <LinearProgress variant="buffer" value={progress} />;
  return (
    <>
      {/* <LinearProgress variant="buffer" value={progress} /> */}
      <LinearProgress
        variant="buffer"
        value={progress}
        valueBuffer={0}
        classes={{
          root: `progress-root ${isVertical && "progress-bar-vertical"}`,
          barColorPrimary: "progress-filled",
          dashedColorPrimary: "progress-dash",
        }}
      />
    </>
  );
};

export default ProgressBar;
